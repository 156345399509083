import React, { PureComponent, Fragment } from "react";

import "../styles/main.scss";

import Seo from "../components/Seo";
import KiraGrace from "../components/ClientPages/KiraGrace";

export default class KiraGracePage extends PureComponent {
  render() {
    return (
      <Fragment>
        <Seo title="Kira Grace" />
        <KiraGrace />
      </Fragment>
    );
  }
}
