import React, { PureComponent } from "react";

import styles from "./Main.module.scss";

import ClientBase from "./ClientBase";

import Video from "../../img/client_pages/kira/video.mp4";
import Feature from "../../img/client_pages/kira/feature.png";
import Desktop from "../../img/client_pages/kira/desktop.gif";
import Iphone from "../../img/client_pages/kira/iphone.png";

export default class KiraGrace extends PureComponent {
  render() {
    return (
      <ClientBase
        mainImgStyle={styles.KiraMainImg}
        video={Video}
        title="Kira Grace"
        theBrand={
          "Kira Grace offers premium yoga apparel for the woman in touch with her spirit. Kira Grace has been worn by Cindy Crawford, Hillary Duff, Elle Fanning, and more."
        }
        ourWork={[
          "Brand Identity",
          "Social Style",
          "Social Strategy",
          "Email Marketing"
        ]}
        brandDesign={
          "To elevate the brand’s premium feel, we emphasized its most basic brand elements and removed what no longer aligned. Our intention was to showcase the story of what makes a Kira Grace woman unique—self-esteem, self-awareness, playful wisdom, and reverence towards the body and spirit."
        }
        featureImg={Feature}
        webDesignText={
          "With an eye toward what already worked on the existing site, we opted to merge the timeless with the modern in streamlining Kira Grace’s visual identity. The new website emphasizes a premium feeling and is set to be fully implemented in early 2019."
        }
        webDesignImg={Desktop}
        socialDesignText={
          "When Kira Grace approached us, the brand already had an established following of almost 25k. We went to work creating clean, beautiful, consistent templates suited to the content they were already posting. That way, transitioning to the new identity would be seamless."
        }
        socialDesignImg={Iphone}
      />
    );
  }
}
